import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import slugify from 'slugify'

import { link } from '../../utilities'

import Image from '../image'
// import { Heart, Phone, Search } from '../icons'

import styles from './header.module.scss'

const Header = ({ title }) => {
  const { site: { siteMetadata: { assets, header } }, allPagesJson } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          assets {
            logoLightBackground
          }
          header {
            showLogo
            showNavigationItems
            navigationItems
            showFeaturedPageButton
            featuredPage
            showFavoritedPage
            favoritedPage
            showCallLink
            callLinkLabel
            showSearchButton
          }
        }
      }
      allPagesJson {
        nodes {
          title
          settings {
            disabled
          }
        }
      }
    }
  `)

  const pages = allPagesJson.nodes.filter(node => !node.settings.disabled).map(node => ({
    title: node.title,
    slug: node.title === 'Home' ? '/' : slugify(node.title).toLowerCase()
  }))

  return (
    <header className={styles.header}>
      {header.showLogo && (
        <>
          <Link to='/'>
            <Image className={styles.logo} src={assets.logoLightBackground} alt={title} />
          </Link>
          <span />
        </>
      )}
      <nav className={styles.nav} role='navigation' aria-label='main navigation'>
        {header.showNavigationItems && (
          <ul className={styles.item + ' ' + styles.list}>
            {header.navigationItems.map((item, i) => {
              // console.log(item)
              const title = pages.find(page => item.includes(page.slug)).title

              return title && (
                <li key={i} className={styles.item}>
                  <Link activeClassName={styles.active} partiallyActive className={styles.link} to={link(item.replace('.json', ''))}>
                    <span className={styles.title}>{title}</span>
                    <span className={styles.bullet}>&#8226;</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        )}
        {/* {showFeaturedPageButton && (
          <div className={styles.item}>
            <Link className={styles.item + ' ' + styles.featured} to={featuredPage.replace('.forestry/content/pages', '').replace('.md', '')}>{pages.find(page => page.fileAbsolutePath.includes(featuredPage)).frontmatter.title || ''}</Link>
          </div>
        )} */}
        {/* {showFavoritedPage && (
          <div className={styles.item}>
            <Link className={styles.item + ' ' + styles.verticalGrid} to={favoritedPage.replace('.forestry/content/pages', '').replace('.md', '')}>
              <Heart className={styles.svg} fill={primaryColor} />
              <strong>{pages.find(page => page.fileAbsolutePath.includes(favoritedPage)).frontmatter.title || ''}</strong>
            </Link>
          </div>
        )} */}
        {/* {showCallLink && (
          <div className={styles.item}>
            <a className={styles.callLink + ' ' + styles.verticalGrid} href='tel:+8001234567'>
              <Phone className={styles.svg} fill={primaryColor} stroke={primaryColor} />
              <span>
                {callLinkLabel}
                <br />
                <strong>{phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3, 6) + '-' + phoneNumber.slice(6)}</strong>
              </span>
            </a>
          </div>
        )} */}
        {/* {showSearchButton && (
          <div className={styles.item + ' ' + styles.search}>
            <Search className={styles.searchIcon} stroke={primaryBackgroundColor} />
          </div>
        )} */}
      </nav>
    </header>
  )
}

export default Header
