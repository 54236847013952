import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const Image = ({ className, src, alt }) => {
  const { allFile: { nodes: images } } = useStaticQuery(graphql`
    {
      allFile {
        nodes {
          publicURL
          relativePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  const image = images.find(image => image.relativePath === 'images/' + src.split('/').pop())

  return image && image.childImageSharp && image.childImageSharp.fluid ? (
    <Img className={className} fluid={image.childImageSharp.fluid} title={alt} alt={alt} />
  ) : image && image.publicURL ? (
    <img className={className} src={image.publicURL} alt={alt} />
  ) : null
}

export default Image
